export const component = () => {
  var $feedbackPanel = $(".callout-box--feedback");
  var $feedbackButton = $feedbackPanel.find(".callout-box__cta");
  var $form = $feedbackPanel.next(".form");

  if ($feedbackPanel.length > 0 && $form.length > 0) {
    var isFormHidden = true;

    //$form.find('.form-group').addClass('intro-hidden');
    $form.hide();

    $feedbackButton.on("click", toggleFeedbackForm);

    //Accesibility attrs
    $feedbackButton.attr("id", "feedback-form-accordion-title");
    $feedbackButton.attr("aria-expanded", !isFormHidden);
    $feedbackButton.attr("aria-controls", "feedback-form-accordion");
    $form.attr("id", "feedback-form-accordion");
    $form.attr("aria-labelledby", "feedback-form-accordion-title");

    function toggleFeedbackForm() {
      if (isFormHidden) {
        $form.slideToggle(300);

        $("html, body").animate(
          {
            scrollTop: $feedbackPanel.offset().top,
          },
          400
        );

        $form.find(".form-group").addClass("-in");
      } else {
        $form.find(".form-group").removeClass("-in");
        $form.slideToggle(300);
      }
      isFormHidden = !isFormHidden;
      $feedbackButton.attr("aria-expanded", !isFormHidden);
    }
  } else if ($feedbackPanel.length > 0 && $form.length === 0) {
    $feedbackButton.hide();
    console.log(
      "Warning: The feedback form was not found so the feedback button was hidden."
    );
  }
};
